<template>
  <div :style="backgroundStyles" :class="useTenantConfig().auth?.showBackgroundImage ? 'bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-60' : 'bg-gray-50 dark:bg-gray-900'">
    <section>
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <NuxtLink :to="useTenantConfig().links?.defaultHomeLinkUrl" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img v-show="$colorMode?.value === 'light'" class="h-8" :src="useTenantTheme().brandLogoLightUrl" :alt="useTenantTheme().brandLogoAlt">
          <img v-show="$colorMode?.value === 'dark'" class="h-8" :src="useTenantTheme().brandLogoDarkUrl" :alt="useTenantTheme().brandLogoAlt">
        </NuxtLink>
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div>
            <slot />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">

// Set html and body classes for Tailwind full screen
useHead({
  bodyAttrs: {
    class: 'h-full'
  },
  htmlAttrs: {
    class: 'h-full bg-white'
  }
})

const $colorMode = useColorMode()

// Compute background image URL using cloudflare images to resize and blur
const backgroundStyles = computed(() => {
  if (!useTenantConfig().auth?.showBackgroundImage) { return '' }
  const imgUrl = useImageResizeUrl(useTenantConfig().auth?.loginBackgroundImageUrl, ['width=1080', 'blur=10'])
  return { backgroundImage: `url('${imgUrl}')` }
})

// // Parse query params to get return URL for after sign-in is completed.
// const query = useQuery()
// const returnUrl = computed(() => {
//   return query.value.returnUrl ?? '/'
// })

</script>

<style scoped>

</style>
